<template id="doActivity">
    <span>
        <div class="md-min-width card m-8">
                <h2 class="text-black">Creacion Documentacion Total Persona</h2>

                <h5 class="text-black">Trabajador asignado a los documentos creados</h5>

                <div class="field" v-if="getEmployee.length > 0">
                    <autoComplete class="w-12rem" v-model="selectedAsignedEmployee" field="first_name1" :suggestions="filteredEmployee" @complete="search($event)" placeholder="Dueño Documentos"></autoComplete>
                    <br />
                    <buttonp class="w-8rem mt-5 ml-1 p-button-warning" icon="pi pi-refresh" label="Cargar Personas" @click.prevent="reloadEmployees()"/>

                    <buttonp class="w-8rem mt-5 ml-2 p-button-warning" icon="pi pi-plus" label="Nueva Persona" @click.prevent="openPanelNewEmployee()"/>

                    <h6 v-if="error.asigned_oblig" class="p-error">Seleccion dueño de la documentacion.</h6>
                </div>   

                <buttonp class="w-10rem mt-5 p-button-warning" icon="pi pi-save" label="Crear Toda Documentacion Persona" @click.prevent="createDocumentation()"/>

                <br/><br/>

                <br/><br/><br/><br/><br/><br/><br/><br/>
                
        </div>
    </span>
</template>


<script>
    import {mapGetters} from 'vuex';
    import DocumentationService from '@/service/DocumentationService';

    let CreateAllDocuPerson = {};
    (function () {
        this.name = 'craete-all-docu-person';
        // let _self = null;

        this.created = function () {
            this.getAllEmployee();
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                allEmployees: [],
                selectedDocumentation: null,
                filteredEmployee: [],
                selectedAsignedEmployee: null,
                docu_implementation: {
                    documentation_id: null,
                    expiration: null,
                    limit_renew: null,
                    asigned_id: null,
                    observation: null,
                }
            }
        };
        this.methods = {
            reloadEmployees(){
                this.getAllEmployee();
            },
            openPanelNewEmployee(){
                window.open("http://127.0.0.1:8000/admin/core/employee/add/");
            },
            createDocumentation(){
                this.$store.dispatch('form/submit', true);

                if(this.selectedAsignedEmployee === null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Persona'});
                    this.$store.dispatch('form/submit', false);
                    return;
                }
                DocumentationService.createAllDocumentationPerson(this.selectedAsignedEmployee.employee_id).then((response) => {
                        var isError =  false;
                        if(response.data.status === 'ERROR'){
                            isError = true;
                        }else{
                            this.$store.dispatch('employee/set-employee', this.selectedAsignedEmployee);
                             this.$router.push({name: 'admin-documentation', 
                                        params: {
                                            docu_id: 0
                                            }
                                        });
                        }
                        this.$store.dispatch('form/result', {isError: isError, message: response.data.message});
                        this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            search(event){
                var filteredResult = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.allEmployees.length; i++) {
                        if (this.allEmployees[i].first_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) ||
                          this.allEmployees[i].last_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) 
                        ) {
                            filteredResult.push(this.allEmployees[i])
                        }
                    }
                    this.filteredEmployee = filteredResult;
                } else {
                    return [];
                }   
            },

            getAllEmployee(){
                this.$store.dispatch('form/submit', true);
                DocumentationService.getEmployeeByBusiness().then((response) => {
                    this.allEmployees = response.data;
                    this.filteredEmployee = response.data;
                    for (var i = 0; i < this.allEmployees.length; i++) {
                            this.allEmployees[i]['first_name1'] = this.allEmployees[i]['first_name1'] +" "+this.allEmployees[i]['first_name2']+' '+this.allEmployees[i]['last_name1'] + ' ' +this.allEmployees[i]['last_name2']+'('+this.allEmployees[i]['card_id'] +'-'+ this.allEmployees[i]['check_digit']+')';
                        }
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            }
        };
        this.computed = {
            getEmployee(){
                return this.filteredEmployee;
            },
            ...mapGetters({
                username: 'auth/user',
                error: 'form/error-states'
            }),
        };
        this.watch = {
            
        };
        this.props = [];
    }).apply(CreateAllDocuPerson);

    export default CreateAllDocuPerson
</script>
