<template id="doActivity">
    <span>
        <div class="md-min-width card m-5" v-if="docu_implementation !== null">

                <!--h3 class="text-black">Editar Documento</h3-->
                <h4 class="text-black">{{selectedAsignedEmployee}}</h4>
                
                <div class="field">
                    <h5 class="text-black">Tipo Documentacion</h5>
                    <Dropdown id="dropDocumentation" disabled v-model="selectedDocumentation" :options="documentations" optionLabel="Nombre" :filter="false" placeholder="Seleccione Documentacion" :showClear="true">
                      <template #value="slotProps">
                          <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                              <span>
                                {{slotProps.value.name}}
                              </span>
                          </div>
                          <span v-else>
                              {{slotProps.placeholder}}
                          </span>
                      </template>
                      <template #option="slotProps">
                      <div class="p-dropdown-supervisors-option">
                        <span>
                              {{slotProps.option.name}}</span>
                      </div>
                    </template>
                    </Dropdown>
                    <h6 v-if="error.act_oblig" class="p-error">Seleccion documentacion obligatoria.</h6>
                </div>

                <div class="field">
                    <h5 class="text-black">Estado de la Documentacion</h5>
                    <Dropdown id="dropDocumentation" disabled v-model="selectedStatus" :options="documentationStatus" optionLabel="Nombre" :filter="false" placeholder="Seleccione Estado" :showClear="true">
                      <template #value="slotProps">
                          <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                              <span>
                                {{slotProps.value.nombre}}
                              </span>
                          </div>
                          <span v-else>
                              {{slotProps.placeholder}}
                          </span>
                      </template>
                      <template #option="slotProps">
                      <div class="p-dropdown-supervisors-option">
                        <span>
                              {{slotProps.option.nombre}}</span>
                      </div>
                    </template>
                    </Dropdown>
                    <h6 v-if="error.act_oblig" class="p-error">Seleccion estado obligatoria.</h6>
                </div>



                
                <span v-if="selectedDocumentation !== null">
                    <h5 class="text-black">Expiracion</h5>
                    <div style="width: 100%">
                        <Checkbox id="binary" v-model="inf_contract" :binary="true" />
                        <label for="binary">Contrato indefinido</label>
                        <br />
                    </div>

                    <Calendar v-if="inf_contract !== true"  id="expiredIdCalendar" v-model="expired" :showTime="false" dateFormat="dd/mm/yy" />
                </span>
                
                <!--h5 class="text-black">Comienzo Renovacion</h5>
                <Calendar id="expiredIdCalendar" v-model="start_renew" :showTime="false" />

                <h5 class="text-black">Renovado/Rechazado</h5>
                <Calendar id="expiredIdCalendar" v-model="renew" :showTime="false" />

                <h5 class="text-black">Limite de comienzo renovacion</h5>
                <Calendar id="expiredIdCalendar" v-model="limit_renew" :showTime="false" /-->

                <!--div class="field mt-4">
                    <h5 class="text-black">Archivo Documentacion</h5>
                    <FileUpload mode="basic" name="demo[]" url="./upload" chooseLabel="Ingrese Comprobante" :customUpload="true" @uploader="myUploader"/>
                </div-->

                <h5 class="text-black">Observacion</h5>
                <Textarea v-model="observation" rows="5" cols="30" />

                <!--h5 class="text-black">Observacion comienzo renovacion</h5>
                <Textarea v-model="observation_start_renew" rows="5" cols="30" />

                <h5 class="text-black">Observacion renovacion</h5>
                <Textarea v-model="observation_renew" rows="5" cols="30" />

                <h5 class="text-black">Observacion Rechazo</h5>
                <Textarea v-model="observation_reject" rows="5" cols="30" /-->
                
                <br/><br/>
                <buttonp class="w-8rem mt-5 p-button-warning" icon="pi pi-save" label="Guardar" @click.prevent="edit()"/>
                <br/><br/>
                <br/><br/>
                <br/><br/>
                

        </div>
    </span>
</template>


<script>
    import {mapGetters} from 'vuex';
    import DocumentationService from '@/service/DocumentationService';
    import UploadFileService from '@/service/UploadFileService';
    import moment from "moment";

    let EditDocumentation = {};
    (function () {
        this.name = 'edit-documentation-compo';
        // let _self = null;

        this.created = function () {
            this.getAllEmployee();
            this.getAllDocumentation();
            this.getAllStatus();
            
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                expired: null,
                start_renew: null,
                renew: null,
                limit_renew: null,
                selectedDocumentation: null,
                selectedStatus: null,
                observation: null,
                observation_start_renew: null,
                observation_renew: null,
                observation_reject: null,
                documentationStatus: [],
                docu_implementation: null,
                filteredEmployee: [],
                allEmployees: [],
                documentations: [],
                upload_data_file: null,
                inf_contract: false,
            }
        };
        this.methods = {
            getDocumentationById(id){
                this.$store.dispatch('form/submit', true);
                DocumentationService.getByid(id).then((response) => {
                    this.docu_implementation = response.data;
                    this.selectedAsignedEmployee = this.getEmployeeById(this.docu_implementation.asigned_id)
                    this.selectedStatus = this.getStatusByname(this.docu_implementation.status)
                    this.selectedDocumentation = this.getDocumentationTypeById(this.docu_implementation.documentation);
                    this.observation = this.docu_implementation.observation;
                    this.observation_start_renew = this.docu_implementation.observation_start_renew;
                    this.observation_renew = this.docu_implementation.observation_renew;
                    this.observation_reject = this.docu_implementation.observation_reject;

                    console.log(this.docu_implementation.expiration);
                    this.expired =  this.getLocalDate(this.docu_implementation.expiration);
                    if(this.expired === '2100/01/01'){
                        this.inf_contract = true;
                    }
                    this.start_renew = this.getLocalDate(this.docu_implementation.start_renew);
                    this.renew = this.getLocalDate(this.docu_implementation.renew);
                    this.limit_renew = this.getLocalDate(this.docu_implementation.limit_renew);

                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getLocalDate(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('DD/MM/YYYY');
                  return local;
                }
            },
            getEmployeeById(id){
                return  this.allEmployees.find(item => item.employee_id === id);
            },
            getStatusByname(name){
                return  this.documentationStatus.find(item => item.name === name);
            },
            getDocumentationTypeById(id){
                var temp = this.documentations.find(item => item.documentation_id === id);
                return  temp
            },
            myUploader(event){
                 this.$store.dispatch('form/submit', true);
                // upload data to the server
                 let result = {isError: false, message: null}
                 var fileList = event.files;
                const formData = new FormData();
                formData.append ('docs', fileList[0]);
                formData.append ('destination', "temp/");

                UploadFileService.uploadfile(formData).then((response) => {
                    this.upload_data_file = response.data;
                    this.docu_implementation.url_file = response.data.full_path;
                    result.message =  response.data.message;
                    if(response.data.status === 'ERROR')
                        result.isError = true;
                    this.$store.dispatch('form/result', result);
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    result.isError = true;
                    if (error.data) {
                        result.message = error.data.message;
                    } else {
                        result.message =  'Ha ocurrido un error interno(uploadfile temp)'
                    }
                    this.$store.dispatch('form/result', result);
                    this.$store.dispatch('form/submit', false);
                })
            },
            search(event){
                var filteredResult = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.allEmployees.length; i++) {
                        if (this.allEmployees[i].first_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) ||
                          this.allEmployees[i].last_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) 
                        ) {
                            filteredResult.push(this.allEmployees[i])
                        }
                    }
                    this.filteredEmployee = filteredResult;
                } else {
                    return [];
                }   
            },
            getAllStatus(){
                this.$store.dispatch('form/submit', true);
                DocumentationService.getDocuStatus().then((response) => {
                    this.documentationStatus = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getAllEmployee(){
                this.$store.dispatch('form/submit', true);
                DocumentationService.getEmployeeByBusiness().then((response) => {
                    this.allEmployees = response.data;
                    this.filteredEmployee = response.data;
                    for (var i = 0; i < this.allEmployees.length; i++) {
                            this.allEmployees[i]['first_name1'] = this.allEmployees[i]['first_name1'] +" "+this.allEmployees[i]['first_name2']+' '+this.allEmployees[i]['last_name1'] + ' ' +this.allEmployees[i]['last_name2']+'('+this.allEmployees[i]['card_id'] +'-'+ this.allEmployees[i]['check_digit']+')';
                        }
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getAllDocumentation(){
                this.$store.dispatch('form/submit', true);
               DocumentationService.allDocumentation().then((response) => {
                    this.documentations = response.data;
                    this.getDocumentationById(this.docu_im_id);
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            edit() {
                this.$store.dispatch('form/reset');
                this.$store.dispatch('form/submit', true)
                this.$store.dispatch('form/error', {
                    ocu_oblig: false,
                });

                if (this.selectedDocumentation === null) {
                    this.$store.dispatch('form/error', {act_oblig: true});
                }

                if (this.$store.getters['form/invalid-fields'].length > 0) {
                    this.$store.dispatch('form/submit', false)
                    return
                }
            
                console.log(this.expired);
                this.docu_implementation.expiration = this.expired;
                //this.docu_implementation.limit_renew = this.limit_renew;
                this.docu_implementation.observation = this.observation;
                //this.docu_implementation.observation_renew = this.observation_renew;
                 //this.docu_implementation.observation_reject = this.observation_reject;
                //this.docu_implementation.observation_start_renew = this.observation_start_renew;
                //this.docu_implementation.renew = this.limit_renew;
                //this.docu_implementation.status = this.selectedStatus.name;
                //this.docu_implementation.documentation_id = this.selectedDocumentation.documentation_id;



               /* this.selectedAsignedEmployee = this.getEmployeeById(this.docu_implementation.asigned_id)
                    this.selectedStatus = this.getStatusByname(this.docu_implementation.status)
                    this.selectedDocumentation = this.getDocumentationTypeById(this.docu_implementation.documentation);


                    this.observation = this.docu_implementation.observation;
                    this.observation_start_renew = this.docu_implementation.observation_start_renew;
                    this.observation_renew = this.docu_implementation.observation_renew;
                    this.observation_reject = this.docu_implementation.observation_reject;

                    this.expired =  this.getLocalDate(this.docu_implementation.expiration);
                    this.start_renew = this.getLocalDate(this.docu_implementation.start_renew);
                    this.renew = this.getLocalDate(this.docu_implementation.renew);
                    this.limit_renew = this.getLocalDate(this.docu_implementation.limit_renew); */

                if(this.selectedDocumentation !== null){
                    if(this.selectedDocumentation.name === 'Contrato Laboral' && this.inf_contract === true){
                        this.docu_implementation.expiration = moment('2100-01-01').format('DD/MM/YYYY');
                    }
                 }

                DocumentationService.editDocumentationImplementation(this.docu_implementation)
                    .then(response => {
                        var isError =  false;
                        if(response.data.status === 'ERROR'){
                            isError = true;
                        }else{
                            this.$emit('okEdit');
                        }
                        this.$store.dispatch('form/result', {isError: isError, message: response.data.message});
                        this.$store.dispatch('form/submit', false)
                    })
                    .catch(err => {
                      console.log(err);
                      this.$store.dispatch('form/submit', false)
                    });
                    
            },
        };
        this.computed = {
            getObservation(){
                return this.observation;
            },
            getEmployee(){
                return this.filteredEmployee;
            },
            ...mapGetters({
                username: 'auth/user',
                error: 'form/error-states'
            }),
        };
        this.watch = {
            
        };
        this.props = ['docu_im_id'];
    }).apply(EditDocumentation);

    export default EditDocumentation
</script>
