<template>
    <span class="z-3">
        <toolbar class="fixed top-0 ml-0 w-12"  style="z-index: 100;">
        <template #start>
            <div class="w-2"><img style="width: 80px !important;" alt="icono corporativo" src="@/assets/logo.svg"></div>
        </template>
        <template #end>
                <span class="hidden md:inline-flex"><autoComplete v-model="selectedColaborator" field="first_name1" :suggestions="filteredEmployeeMultiple" @complete="searchDriversMultiple($event)" placeholder="Busque colaborador"></autoComplete>
                    <buttonp icon="pi pi-arrow-circle-right" @click.prevent="goMonthDetailEmployee() " />
                </span>
                <buttonp icon="pi pi-bars" @click.prevent="openSideBar() " />
        </template>
        </toolbar>
        <sidebarp v-model:visible="visibleRight" position="right">
            <autoComplete v-model="selectedColaborator" field="first_name1" :suggestions="filteredEmployeeMultiple" @complete="searchDriversMultiple($event)" placeholder="Busque conductor"></autoComplete><buttonp icon="pi pi-arrow-circle-right" @click.prevent="goMonthDetailEmployee() " />
            <Menu :model="items" />
        </sidebarp>
    </span>
</template>

<script>

    import EmployeeService from '@/service/EmployeeService';
   
    let ToolbarComp = {};
    (function () {

        this.name = 'tooolbar';

        this.created = function () {
            this.getAllColaborator();
        };
        this.data = function () {
            return {
                selectedColaborator: null,
                all_colaboratrs: [],
                filteredEmployeeMultiple: [],
                visibleRight: false,
                items: [
                     {
                        label: 'Consulta',
                        items: [
                        {label: 'Por Division', icon: 'pi pi-fw pi-plus',  to: '/all-collaborator-report' },
                        {label: 'Por Colaborador', icon: 'pi pi-fw pi-plus',  to: '/collaborator-report' },
                        {label: 'Tipo Documentacion', icon: 'pi pi-fw pi-plus', to: '/docu-type-report' },
                        ]
                    },
                    {
                        label: 'Ingresos',
                        items: [
                        {label: 'Gestion Por Colaborador', icon: 'pi pi-fw pi-plus',  to: '/admin-documentation' },
                        {label: 'Nueva documentacion', icon: 'pi pi-fw pi-plus',  to: '/new-documentation' }
                        ]
                    },
                    {
                        label: 'Sistema',
                        items: [{label: 'Inicio', icon: 'pi pi-hm pi-home', to: '/home'},
                                {label: 'Salir', icon: 'pi pi-fw pi-power-off', to: '/logout'},
                                ]
                    }
                ],  
            }
        };
        this.methods = {
              searchDriversMultiple(event){
                var filteredResultDrivers = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.all_colaboratrs.length; i++) {
                        if (this.all_colaboratrs[i].first_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) ||
                          this.all_colaboratrs[i].last_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) 
                        ) {
                            filteredResultDrivers.push(this.all_colaboratrs[i])
                        }
                    }
                    this.filteredEmployeeMultiple = filteredResultDrivers;
                } else {
                    return [];
                }   
            },
            getAllColaborator(){
                 EmployeeService.getAll().then((response) => {
                        this.all_colaboratrs= response.data;
                        for (var i = 0; i < this.all_colaboratrs.length; i++) {
                            this.all_colaboratrs[i]['first_name1'] = this.all_colaboratrs[i]['first_name1'] +" "+this.all_colaboratrs[i]['first_name2']+' '+this.all_colaboratrs[i]['last_name1'] + ' ' +this.all_colaboratrs[i]['last_name2']+'('+this.all_colaboratrs[i]['card_id'] +'-'+ this.all_colaboratrs[i]['check_digit']+')';
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            goMonthDetailEmployee(){
                this.$store.dispatch('form/submit', true)
                let result = {isError: false, message: null}

                if(this.selectedColaborator == null){
                    result.isError = true;
                    result.message = 'Seleccione Colaborador';
                    this.$store.dispatch('form/result', result);
                    this.$store.dispatch('form/submit', false)
                    return;
                }

                this.$store.dispatch('employee/set-employee', this.selectedColaborator);
                this.$router.push({name: 'redirect-admin-documentation', params: { id: 0 } });
            },
            goHome(){
                this.$router.push({name: 'home'});
            },
            openSideBar(){
                this.visibleRight = true;
            },
            logOut() {
                this.$router.push({name: 'logout'});
            },
            goTo: function (route) {
                this.$router.push({name: route});
            }
        };
        this.mounted = function () {};
        this.components = {}
        this.computed = {};
        this.watch = {};
        this.props = [];
    }).apply(ToolbarComp);
    export default ToolbarComp;
</script>

<style scoped>

</style>
