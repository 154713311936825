
<template id="finishrenew">
        <renewdocu :id_docu_impl="selectedIdDocumentation" :id_docu="selectedDocuType" :employee_id="selectedAsigned" @ok-edit="goEdit"></renewdocu>
</template>


<script>
    import {mapGetters} from 'vuex';
    import Renewdocu from '@/components/RenewDocuComponent'

    let FinishRenew = {};
    (function () {
        this.name = 'finish-documentation-view';
        // let _self = null;

        this.created = function () {
            this.selectedIdDocumentation = this.$route.params.documentation_implementation_id;
            this.selectedDocuType = this.$route.params.documentation_id;
            this.selectedAsigned = this.$route.params.employee_id;
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                selectedIdDocumentation: null,
                selectedDocuType: null,
                selectedAsigned: null,
            }
        };
        this.methods = {
            goEdit(value){
                console.log(value);
                this.$router.push({name: 'edit-documentation',
                                   params: {
                                        documentation_implementation_id: value,
                                    }
                               }); 
            }
        };
        this.components = {Renewdocu

        };
        this.computed = {
            ...mapGetters({

            }),
        };
        this.watch = {
            
        };
        this.props = [];
    }).apply(FinishRenew);

    export default FinishRenew
</script>
