<template id="calendarActivity">
    <div >

        <h4 class="text-black">MI DOCUMENTACION</h4>
        
        <div v-if="colaboratorDetail.length > 0" >

            <div class="card ml-6 mr-6">
                <h4 class="text-black">TOTALES</h4>
                <h5 class="text-black">{{colaboratorDetail.length}} Documentos encotrados.</h5>
            </div>

            <DataTable id="tableDetailCOlaborador" :value="colaboratorDetail" responsiveLayout="scroll">
                <Column header="ID" :sortable="true">
                    <template #body="slotProps">
                    <buttonp :label="slotProps.data.documentation_implementation_id" @click="openDetailDocumentation(slotProps.data.documentation_implementation_id)" autofocus />
                </template>
                </Column>
                <Column field="documentation_id" header="Tipo Documento" :sortable="true">
                    <span v-if="slotProps.data.documentation_id !== null">{{getDocumentationById(slotProps.data.documentation_id)}}</span>
                </Column>
                <Column field="expiration" header="Expiracion" :sortable="true">
                    <template #body="slotProps">
                        {{getLocalDate(slotProps.data.expiration)}}
                    </template>
                </Column>
                <Column field="status" header="ESTADO" :sortable="true"></Column>
                <Column field="limit_renew" header="Limite C.Renovacion" :sortable="true">
                    <template #body="slotProps">
                        {{getLocalDate(slotProps.data.limit_renew)}}
                    </template>
                </Column>
                <Column field="start_renew" header="Comenzo Renovacion" :sortable="true"></Column>
                <Column field="renew" header="Fecha Renovacion" :sortable="true"></Column>
            </DataTable>
            
        </div>
        <div v-else>
            <h2>Sin Resultados</h2>
        </div>
        
    </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    import DocumentationService from '@/service/DocumentationService';
    //import EmployeeService from '@/service/EmployeeService';
    import moment from "moment";


    let MyResult = {};
    (function () {
        this.name = 'my-documentation';

        this.created = function () {
            this.getReport();
            this.getAllDocumentation();
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                selectedContract: null,
                colaboratorDetail: [],
                documentations:[],
            }
        };
        this.methods = {
            getDocumentationById(id){
               return  this.documentations.find(item => item.documentation_id === id).name;
            },
            getAllDocumentation(){
                this.$store.dispatch('form/submit', true);
               DocumentationService.allDocumentation().then((response) => {
                    this.documentations = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            openDetailDocumentation(){
                this.$router.push({name: 'new-documentation'});
            },
            getLocalDate(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('YYYY-MM-DD');
                  return local;
                }
            },
            getReport() {
                this.$store.dispatch('form/submit', true)
                let result = {isError: false, message: null}

                DocumentationService.colaboratorReport(this.username)
                    .then(response => {
                         this.colaboratorDetail = response.data;
                    if(response.data.length === 0){
                        result.isError = true;
                        result.message = 'No tiene documentos registrados';
                        this.$store.dispatch('form/result', result);
                    }

                    this.$store.dispatch('form/submit', false);
                    })
                    .catch(err => {
                      console.log(err);
                       this.$store.dispatch('form/submit', false)
                    });
                    
            },
        };
        this.computed = {
            ...mapGetters({
                username: 'auth/user'
            }),
        };
        this.watch = {

        };
        this.props = [];
    }).apply(MyResult);

    export default MyResult
</script>
