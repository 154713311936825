<template id="EditDocumentationview">
    <span>
        <editdocu :docu_im_id="selectedIdDocumentation" v-on:ok-edit="goAdmin"></editdocu> 
    </span>
</template>


<script>
    import {mapGetters} from 'vuex';
    import Editdocu from '@/components/EditDocuComponent';

    let EditDocumentation = {};
    (function () {
        this.name = 'edit-documentation-view';
        // let _self = null;

        this.created = function () {
            console.log(this.$route.params.documentation_implementation_id);
            this.selectedIdDocumentation = this.$route.params.documentation_implementation_id;
            
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                selectedIdDocumentation: null,
            }
        };
        this.components = {Editdocu}
        this.methods = {
            getSelectedId(){
                return this.selectedIdDocumentation;
            },
            goAdmin(){
                this.$router.push({name: 'admin-documentation', params: { docu_id: this.selectedIdDocumentation } });
            }
        };
        this.computed = {
            ...mapGetters({

            }),
        };
        this.watch = {
            
        };
        this.props = [];
    }).apply(EditDocumentation);

    export default EditDocumentation
</script>
