import axios from "axios";

export default {


    
    allLogs() {
        const url = `${process.env.VUE_APP_API_DOCU_URL}/alllogsdocu`;
        return axios.get(url)
    },


    isActiveEmployee(employee_id) {
        const url = `${process.env.VUE_APP_API_DOCU_URL}/isactiveemployeebydoc`;
        let data = {employee_id: employee_id}
        return axios.post(url, data)
    },

    editDocumentationImplementation(docu) {
        const url = `${process.env.VUE_APP_API_DOCU_URL}/editdocumentationimplementation`;
        let data = {docu}
        return axios.post(url, data)
    },
    
    newDocumentationImplementation(docu) {
        const url = `${process.env.VUE_APP_API_DOCU_URL}/newdocumentationimplementation`;
        let data = {documentation_implementation: docu}
        return axios.post(url, data)
    },

    documentationStartRenew(obs, id) {
        const url = `${process.env.VUE_APP_API_DOCU_URL}/docustartrenew`;
        let data = {obs: obs, id: id}
        return axios.post(url, data)
    },

    documentationRenew(obs, id, doc_id, employee_id, expiration_new_doc) {
        const url = `${process.env.VUE_APP_API_DOCU_URL}/docufinishrenew`;
        let data = {obs: obs, id: id, doc_id: doc_id, employee_id:employee_id, expiration: expiration_new_doc}
        return axios.post(url, data)
    },

    documentationReject(obs, id) {
        const url = `${process.env.VUE_APP_API_DOCU_URL}/docureject`;
        let data = {obs: obs, id: parseInt(id)}
        return axios.post(url, data)
    },

    startDocumentationRenew(docu) {
        const url = `${process.env.VUE_APP_API_DOCU_URL}/startdocumentationrenew`;
        let data = {documentation_implementation: docu}
        return axios.post(url, data)
    },

    allColaboratorReport(contract_id) {
        const url = `${process.env.VUE_APP_API_DOCU_URL}/allcolaboratorreport`;
        let data = {contract_id: contract_id}
        return axios.post(url, data)
    },

    colaboratorReport(employee_id) {
        const url = `${process.env.VUE_APP_API_DOCU_URL}/colaboratorreport`;
        let data = {employee_id: employee_id}
        return axios.post(url, data)
    },

    docuTypeReport(contract_id) {
        const url = `${process.env.VUE_APP_API_DOCU_URL}/docutypereport`;
        let data = {contract_id: contract_id}
        return axios.post(url, data)
    },

    myAlertDocumentation() {
        const url = `${process.env.VUE_APP_API_DOCU_URL}/myalertdocumentation`;
        return axios.get(url)
    },

    allDocumentation(){
        const url = `${process.env.VUE_APP_API_DOCU_URL}/alldocs`;
        return axios.get(url)
    },

    getEmployeeByBusiness() {
        const url = `${process.env.VUE_APP_API_CORE_URL}/employees`;
        return axios.get(url);
    },

    createAllDocumentationPerson(id){
        const url = `${process.env.VUE_APP_API_DOCU_URL}/createalldocuperson`;
        let data = {id: id}
        return axios.post(url, data)
    },

    delete(id){
        const url = `${process.env.VUE_APP_API_DOCU_URL}/delete`;
        let data = {id: id}
        return axios.post(url, data)
    },

    getByid(id){
        const url = `${process.env.VUE_APP_API_DOCU_URL}/getdocuid`;
        let data = {id:id}
        return axios.post(url, data)
    },

    getDocuStatus() {
        const url = `${process.env.VUE_APP_API_DOCU_URL}/docustatus`;
        return axios.get(url);
    },


}
