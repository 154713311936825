import { createWebHistory, createRouter } from "vue-router";
import Login from '../views/LoginPage';
import Home from '../views/HomePage';
import SelectContract from '../views/SelectContract';
import NewDocumentation from '../views/NewDocumentation';
import StartProcessRenew from '../views/StartProcessRenew';
import FinishRenew from '../views/FinishRenew';
import MyResult from '../views/MyResult';
import AllCollaboratorsReport from '../views/AllCollaboratorsReport';
import ColaboratorReport from '../views/ColaboratorReport';
import AdminDocumentation from '../views/AdminDocumentation';
import EditDocumentation from '../views/EditDocumentation';
import RejectDocumentation from '../views/RejectDocumentation';
import DocuTypeReport from '../views/DocuTypeReport';
import AdminAllEmployeeDocu from '../views/AdminAllEmployeeDocu';
import AdminDocuType from '../views/AdminDocuType';
import CreateAllDocuPerson from '../views/CreateAllDocuPerson';
import LogDocumentation from '../views/LogDocumentation';
import RedirectAdminDocumentation from '../views/RedirectDocuAdmin';


const routes = [
  {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            requiresAuth: false,
            module: 'authentication'
        }
    },
    {
        path: '/home',
        alias: ['/home'],
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: true,
            module: 'home'
        }
    },
    {
        path: '/select-contract',
        alias: ['/select-contract'],
        name: 'select-contract',
        component: SelectContract,
        meta: {
            requiresAuth: true,
            module: 'contract'
        }
    },
    {
        path: '/new-documentation',
        alias: ['/new-documentation'],
        name: 'new-documentation',
        component: NewDocumentation,
        meta: {
            requiresAuth: true,
            module: 'Documentation'
        }
    },
    {
        path: '/start-process-renew/:documentation_implementation_id',
        alias: ['/start-process-renew'],
        name: 'start-process-renew',
        component: StartProcessRenew,
        meta: {
            requiresAuth: true,
            module: 'Documentation'
        }
    },
    {
        path: '/finish-renew/:documentation_implementation_id/:documentation_id/:employee_id',
        alias: ['/finish-renew'],
        name: 'finish-renew',
        component: FinishRenew,
        meta: {
            requiresAuth: true,
            module: 'Documentation'
        }
    },
    {
        path: '/reject-documentation/:documentation_implementation_id',
        alias: ['/reject-documentation'],
        name: 'reject-documentation',
        component: RejectDocumentation,
        meta: {
            requiresAuth: true,
            module: 'Documentation'
        }
    },
    {
        path: '/my-documentation',
        alias: ['/my-documentation'],
        name: 'my-documentation',
        component: MyResult,
        meta: {
            requiresAuth: true,
            module: 'reports'
        }
    },
     {
        path: '/create-all-docu-person',
        alias: ['/create-all-docu-person'],
        name: 'create-all-docu-person',
        component: CreateAllDocuPerson,
        meta: {
            requiresAuth: true,
            module: 'reports'
        }
    },
    {
        path: '/all-collaborator-report',
        alias: ['/all-collaborator-report'],
        name: 'all-collaborator-report',
        component: AllCollaboratorsReport,
        meta: {
            requiresAuth: true,
            module: 'reports'
        }
    },
    {
        path: '/collaborator-report',
        alias: ['/collaborator-report'],
        name: 'collaborator-report',
        component: ColaboratorReport,
        meta: {
            requiresAuth: true,
            module: 'reports'
        }
    },
     {
        path: '/docu-type-report',
        alias: ['/docu-type-report'],
        name: 'docu-type-report',
        component: DocuTypeReport,
        meta: {
            requiresAuth: true,
            module: 'reports'
        }
    },
    {
        path: '/admin-documentation/:docu_id',
        alias: ['/admin-documentation'],
        name: 'admin-documentation',
        component: AdminDocumentation,
        meta: {
            requiresAuth: true,
            module: 'Documentation'
        }
    },
     {
        path: '/redirect-admin-documentation/:id',
        name: 'redirect-admin-documentation',
        component: RedirectAdminDocumentation,
        meta: {
            requiresAuth: true,
            module: 'Documentation'
        }
    },
    {
        path: '/admin-all-employee-docu',
        alias: ['/admin-all-employee-docu'],
        name: 'admin-all-employee-docu',
        component: AdminAllEmployeeDocu,
        meta: {
            requiresAuth: true,
            module: 'Documentation'
        }
    },
    {
        path: '/admin-docu-type',
        alias: ['/admin-docu-type'],
        name: 'admin-docu-type',
        component: AdminDocuType,
        meta: {
            requiresAuth: true,
            module: 'Documentation'
        }
    },
    {
        path: '/edit-documentation/:documentation_implementation_id',
        alias: ['/edit-documentation'],
        name: 'edit-documentation',
        component: EditDocumentation,
        meta: {
            requiresAuth: true,
            module: 'Documentation'
        }
    },
    {
        path: '/log-documentation/',
        alias: ['/log-documentation'],
        name: 'log-documentation',
        component: LogDocumentation,
        meta: {
            requiresAuth: true,
            module: 'Documentation'
        }
    },
    {
        name: 'logout',
        path: '/logout',
        redirect: to => {
            console.log(to);
            return {name: 'login'};
        },
        meta: {
            requiresAuth: false,
            module: 'authentication'
        }
    },

]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

routes.map((route) => {
    if (!route.meta) route.meta = {};
    if (route.meta.requiresAuth !== false)
        route.meta.requiresAuth = true;
});

export default router
