<template id="calendarActivity">
    <div >
        <h4 class="text-black">Reporte Colaboradores</h4>

        <div class="card ml-3 mr-3">
                <div class="field">
                    <label for="contractdropdown" class="p-sr-only">Colaborador</label>
                    <div class="field">
                        <autoComplete v-model="selectedColaborator" field="first_name1" :suggestions="filteredDriversMultiple" @complete="searchDriversMultiple($event)" placeholder="Busque conductor"></autoComplete>
                        <buttonp class="w-8rem p-button-warning  ml-2" icon="pi pi-search" label="BUSCAR" @click.prevent="getReport()"/>
                    </div>
                </div>
        </div>
        
        <div v-if="colaboratorDetail.length > 0" >

             <h4 class="text-black">Totales documentos evaluados: {{total_report.total_eval}}</h4>

                <div v-if="colaboratorDetail.length > 0" class="grid text-black">
                    <div class="col-12 md:col-6 lg:col-2 col-offset-1"> <buttonp :label="'Alertas '+ total_report.alert" class=" p-button-sm p-button-warning" icon="pi pi-exclamation-circle" v-tooltip.left="'Total Alertas del emppleado evaluado'" autofocus/></div>

                    <div class="col-12 md:col-6 lg:col-2"><buttonp :label="'Criticos '+ total_report.critic" class=" p-button-sm p-button-danger p-button-outlined" icon="pi pi-exclamation-circle" v-tooltip.left="'Total Documentos Criticos del emppleado evaluado'" autofocus/></div>

                    <div class="col-12 md:col-6 lg:col-2"><buttonp :label="'Validos '+ total_report.valid" class=" p-button-sm p-button-success" icon="pi pi-check" v-tooltip.left="'Total Documentos Validos del emppleado evaluado.'" autofocus/></div>

                     <div class="col-12 md:col-6 lg:col-2"><buttonp :label="'Expirados '+ total_report.expired" class=" p-button-sm p-button-danger" icon="pi pi-exclamation-circle" v-tooltip.left="'Total Documentos Expirados del emppleado evaluado.'" autofocus/></div>

                    <div class="col-12 md:col-6 lg:col-2"><buttonp :label="'Revisar '+ total_report.valid" class="p-button-sm p-button-warning p-button-outlined" icon="pi pi-exclamation-circle" v-tooltip.left="'Total Documentos por Revisar del emppleado evaluado.'" autofocus/></div>

                </div>

            <div class="card ml-6 mr-6">
                <h4 class="text-black">TOTALES <span v-if="selectedColaborator.first_name1">{{selectedColaborator.first_name1}}</span>
                    <span v-if="is_active_employee">
                        <buttonp v-if="is_active_employee.is_active" :label="'Empleado Activo'" class="ml-1 p-button-sm p-button-success" icon="pi pi-exclamation-circle" v-tooltip.left="is_active_employee.message" autofocus/>
                        <buttonp v-else :label="'Empleado Inactivo'" class="ml-1 p-button-sm p-button-danger" icon="pi pi-exclamation-circle" v-tooltip.left="is_active_employee.message" autofocus/>
                    </span>
                </h4>
                <h5 class="text-black">{{colaboratorDetail.length}} Documentos encotrados.</h5>

                    <buttonp class="w-8rem p-button-warning mb-2" icon="pi pi-file-excel" label="Exportar Excel" @click.prevent="exportExcel()"/>
                
            </div>

            <DataTable id="tableDetailCOlaborador" :value="colaboratorDetail" responsiveLayout="scroll">
                <!--olumn header="ID" :sortable="true">
                    <template #body="slotProps">
                    <buttonp :label="slotProps.data.documentation_implementation_id" @click="openDetailDocumentation(slotProps.data.documentation_implementation_id)" autofocus />
                </template>
                </Column-->
                <Column field="documentation_id" header="Tipo Documento" :sortable="true">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.documentation_id !== null">
                        {{getDocumentationById(slotProps.data.documentation_id)}}</span>
                    </template>
                </Column>
                <Column field="expiration" header="Expiracion" :sortable="true">
                    <template #body="slotProps">
                       

<span v-if="slotProps.data.expiration != null">
                        <buttonp v-if="slotProps.data.calculate_status == 'ALERT'" :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-warning" icon="pi pi-exclamation-circle" v-tooltip.left="'DOCUMENTO EN ALERTA'" autofocus/>

                            <buttonp v-if="slotProps.data.calculate_status == 'CRITIC'"  :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-danger p-button-outlined" icon="pi pi-exclamation-circle" v-tooltip.left="'DOCUMENTO CRITICO'" autofocus />

                            <buttonp v-if="slotProps.data.calculate_status == 'EXPIRED'"  :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-danger" icon="pi pi-times" v-tooltip.left="'DOCUMENTO EXPIRADO'" autofocus/>
                            <buttonp v-if="slotProps.data.calculate_status == 'OK' && slotProps.data.expiration !== '2100-01-01T04:00:00Z'"  :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-success" icon="pi pi-check" v-tooltip.left="'DOCUMENTO NO EXPIRADO'" autofocus/>

                            <buttonp v-if="slotProps.data.calculate_status == 'OK' && slotProps.data.expiration === '2100-01-01T04:00:00Z'"  :label="'Indefinido'" class="ml-1 p-button-sm p-button-success" icon="pi pi-check" v-tooltip.left="'DOCUMENTO NO EXPIRADO'" autofocus/>
                            
                            <buttonp v-if="slotProps.data.calculate_status == null"  :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-warning p-button-outlined" icon="pi pi-check" v-tooltip.left="'REVISAR DOCUMENTO'" autofocus/>
</span>
 <p v-else class="text-red"> Sin Fecha</p>

                    </template>
                </Column>
                <Column header="ESTADO" :sortable="true">
                     <template #body="slotProps">
                        <statustag :status="slotProps.data.status"></statustag>
                    </template>
                </Column>
                <!--Column field="limit_renew" header="Limite C.Renovacion" :sortable="true">
                    <template #body="slotProps">
                        {{getLocalDate(slotProps.data.limit_renew)}}
                    </template>
                </Column-->
                <!--Column field="start_renew" header="Comenzo Renovacion" :sortable="true"></Column-->
                <!--Column field="renew" header="Fecha Renovacion" :sortable="true"></Column-->
            </DataTable>
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            
        </div>
        <div v-else>
            <h2>Sin Resultados</h2>
        </div>
        
    </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    import DocumentationService from '@/service/DocumentationService';
    import EmployeeService from '@/service/EmployeeService';
    import Statustag from '@/components/StatusTag';
    import moment from "moment";


    let colaboratorReport = {};
    (function () {
        this.name = 'all-colaborator-reports';

        this.created = function () {
            this.getAllColaborator();
            this.getAllDocumentation();
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                contracts: [],
                selectedContract: null,
                colaboratorDetail: [],
                selectedDetails: [],
                displayDetailDocs: false,
                selectedColaborator: null,
                filteredDriversMultiple: [],
                all_colaboratrs: [],
                documentations: [],
                is_active_employee: null,
                total_report: [],
            }
        };
        this.methods = {
            isActiveEmployee(id){
                this.$store.dispatch('form/submit', true);
                let result = {isError: false, message: null}
               DocumentationService.isActiveEmployee(id).then((response) => {
                    this.is_active_employee = response.data;
                    if(response.data.status === 'ERROR'){
                        result.isError = true;
                        result.message = response.data.message;
                        this.$store.dispatch('form/result', result);
                    }
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getDocumentationById(id){
               return  this.documentations.find(item => item.documentation_id === id).name;
            },
            getAllDocumentation(){
                this.$store.dispatch('form/submit', true);
               DocumentationService.allDocumentation().then((response) => {
                    this.documentations = response.data;
                    console.log(this.searchemployee);
                    if(this.searchemployee === '[object Object]' || this.searchemployee === null){
                        this.searchemployee = null;
                        this.$store.dispatch('employee/employee', null);
                    }
                    if(this.searchemployee !== null){
                        this.selectedColaborator = this.searchemployee;
                        this.getReport();
                    }
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            exportExcel(){

            },
            getAllColaborator(){
                 EmployeeService.getAll().then((response) => {
                        this.all_colaboratrs= response.data;
                        for (var i = 0; i < this.all_colaboratrs.length; i++) {
                            this.all_colaboratrs[i]['first_name1'] = this.all_colaboratrs[i]['first_name1'] +" "+this.all_colaboratrs[i]['first_name2']+' '+this.all_colaboratrs[i]['last_name1'] + ' ' +this.all_colaboratrs[i]['last_name2']+'('+this.all_colaboratrs[i]['card_id'] +'-'+ this.all_colaboratrs[i]['check_digit']+')';
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
             searchDriversMultiple(event){
                var filteredResultDrivers = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.all_colaboratrs.length; i++) {
                        if (this.all_colaboratrs[i].first_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) ||
                          this.all_colaboratrs[i].last_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) 
                        ) {
                            filteredResultDrivers.push(this.all_colaboratrs[i])
                        }
                    }
                    this.filteredDriversMultiple = filteredResultDrivers;
                } else {
                    return [];
                }   
            },
            openDetailDocumentation(id){
                this.$store.dispatch('employee/set-employee', this.selectedColaborator);
                this.$router.push({name: 'admin-documentation', params: { docu_id: id } });

            },
            getLocalDate(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('YYYY-MM-DD');
                  return local;
                }
            },
            getReport() {
                this.$store.dispatch('form/submit', true)
                let result = {isError: false, message: null}

                if(this.selectedColaborator == null){
                    result.isError = true;
                    result.message = 'Seleccione Colaborador';
                    this.$store.dispatch('form/result', result);
                    this.$store.dispatch('form/submit', false)
                    return;
                }
                this.total_report = [];
                this.colaboratorDetail = [];
                this.isActiveEmployee(this.selectedColaborator.employee_id);
                DocumentationService.colaboratorReport(this.selectedColaborator.employee_id)
                    .then(response => {
                         this.colaboratorDetail = response.data.detail;
                          this.total_report = response.data.totals;
                    if(response.data.length === 0){
                        result.isError = true;
                        result.message = 'Sin resultados';
                        this.$store.dispatch('form/result', result);
                    }

                    this.$store.dispatch('form/submit', false);
                    })
                    .catch(err => {
                      console.log(err);
                       this.$store.dispatch('form/submit', false)
                    });
                    
            },
        };
        this.components = {Statustag};
        this.computed = {
            ...mapGetters({
                username: 'auth/user',
                searchemployee: 'employee/employee',
            }),
        };
        this.watch = {

        };
        this.props = [];
    }).apply(colaboratorReport);

    export default colaboratorReport
</script>
