<template id="renewdocucomponent">
        <div class="md-min-width card m-5">
                <h3 v-if="show_title" class="text-black">Finalizacion de renovacion documento</h3>

                <p class="text-black">Realizando esta accion usted estara renovando y se realizara la creacion de un nuevo DOCUMENTO</p>

                <h5 class="text-black">Expiracion <Tag class="mr-2"  severity="danger" value="NUEVO"></Tag> Documento
                </h5>
                <Calendar id="expiredIdCalendar" v-model="expiration_new_doc" :showTime="false" />

                <h5 class="text-black">Observacion renovacion</h5>
                <Textarea v-model="observation_renew" rows="5" cols="30" />

                <br />
                <buttonp class="w-8rem mt-5 p-button-warning" icon="pi pi-save" label="Finalizar Renovacion" @click.prevent="finishRenew()"/>
                
        </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    import DocumentationService from '@/service/DocumentationService';

    let RenewDocuComponent = {};
    (function () {
        this.name = 'renew-documentation-compo';
        // let _self = null;

        this.created = function () {
            
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                observation_renew: null,
                expiration_new_doc: null,
            }
        };
        this.methods = {

            finishRenew() {
                this.$store.dispatch('form/reset');
                this.$store.dispatch('form/submit', true)
                this.$store.dispatch('form/error', {
                    ocu_oblig: false,
                    asigned_oblig: false,
                });

                if (this.$store.getters['form/invalid-fields'].length > 0) {
                    this.$store.dispatch('form/submit', false)
                    return
                }

                DocumentationService.documentationRenew(this.observation_renew, this.id_docu_impl, this.id_docu, this.employee_id, this.expiration_new_doc)
                    .then(response => {
                        var isError =  false;
                        if(response.data.status === 'ERROR'){
                            isError = true;
                        }else{
                            this.$emit('okEdit', response.data.data);
                        }
                        this.$store.dispatch('form/result', {isError: isError, message: response.data.message});
                        this.$store.dispatch('form/submit', false)
                    })
                    .catch(err => {
                      console.log(err);
                      this.$store.dispatch('form/submit', false)
                    });
            },
        };
        this.computed = {
            ...mapGetters({
                error: 'form/error-states'
            }),
        };
        this.watch = {
            
        };
        this.props = ['id_docu_impl', 'id_docu', 'employee_id', 'show_title'];
    }).apply(RenewDocuComponent);

    export default RenewDocuComponent
</script>
