<template id="rejectdocuview">
        <rejectdocu :id_docu_impl="selectedIdDocumentation" v-on:ok-edit="goAdmin"></rejectdocu> 
</template>


<script>
    import {mapGetters} from 'vuex';
    import Rejectdocu from '@/components/RejectDocuComponent'

    let RejectDocumentation = {};
    (function () {
        this.name = 'reject-documentation-view';
        // let _self = null;

        this.created = function () {
            this.selectedIdDocumentation = this.$route.params.documentation_implementation_id;
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                selectedIdDocumentation: null,
            }
        };
        this.methods = {
             goAdmin(){
                this.$router.push({name: 'admin-documentation'});
            }
        };
        this.components = {Rejectdocu

        };
        this.computed = {
            ...mapGetters({

            }),
        };
        this.watch = {
            
        };
        this.props = [];
    }).apply(RejectDocumentation);

    export default RejectDocumentation
</script>




