const state = () => ({
	employee: localStorage.getItem('employee'),
});
 
const getters = {
	employee(state) {
        return state.employee
    },
};
 
const actions = {
	'set-employee'(context, employee) {
        context.commit('employee', employee);
        localStorage.setItem('employee', employee);
    },
};
 
const mutations = {
	employee(state, value) {
		state.employee = value
	},
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}