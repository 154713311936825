<template id="deleteDocuImplementation">
    <span v-if="only_confirmation === false">
        <buttonp label="" class="ml-1 is-red p-button-sm" icon="pi pi-trash" v-tooltip.left="'Eliminar Documento'" @click="deleteDocumentation(id_docu_impl)" autofocus />
    </span>
    <span v-else>
        <p>¿Desea borrar documentacion?</p>
         <buttonp label="Eliminar Documentacion" class="ml-1 is-red p-button-sm" icon="pi pi-trash" @click="delDocumentation(id_docu_impl)" autofocus />
    </span>
</template>


<script>
    import {mapGetters} from 'vuex';
    import DocumentationService from '@/service/DocumentationService';


    let DeleteDocuComponent = {};
    (function () {
        this.name = 'delete-docu-implem';

        this.created = function () {

        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {

            }
        };
        this.methods = {
            delDocumentation(id){
                let result = {isError: false, message: null}
                        this.$store.dispatch('form/reset');
                        this.$store.dispatch('form/submit', true);
                        DocumentationService.delete(id).then((response) => {
                            if(response.data.status === 'OK'){
                                result.isError = false;
                                result.message = response.data.message;
                                this.$store.dispatch('form/result', result);
                                this.$store.dispatch('form/submit', false);
                                this.$emit('okEdit');
                                //this.getReport();
                            } 
                        }).catch((error) => {
                            console.log(error);
                            this.$store.dispatch('form/submit', false);
                        });
            },
            deleteDocumentation(id) {
                this.$confirm.require({
                    message: 'Desea borrar documentacion?',
                    header: 'Eliminar Documentacion',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        this.delDocumentation(id);
                    },
                    reject: () => {
                        
                    }
                });
            },

  

 

        };
        this.components = {};
        this.computed = {
            ...mapGetters({
                username: 'auth/user',
            }),
        };
        this.watch = {

        };
        this.props = ['id_docu_impl', 'only_confirmation'];
    }).apply(DeleteDocuComponent);

    export default DeleteDocuComponent
</script>
