<template id="calendarActivity">
    <div>
        <h4 class="text-black">Reporte Todos Los Colaboradores</h4>
        <div class="card ml-3 mr-3 p-grid p-nogutter p-d-flex p-justify-center p-ai-cente">
                <div class="field">
                    <label for="contractdropdown" class="p-sr-only">Contrato</label>
                    <div class="field">
                        <Dropdown id="contractdropdown" v-model="selectedContract" :options="contracts" optionLabel="Name" :filter="false" placeholder="Todos los Contratos" :showClear="true">
                          <template #value="slotProps">
                              <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                  <span>
                                    {{slotProps.value.name}}
                                  </span>
                              </div>
                              <span v-else>
                                  {{slotProps.placeholder}}
                              </span>
                          </template>
                          <template #option="slotProps">
                          <div class="p-dropdown-supervisors-option">
                            <span>
                                  {{slotProps.option.name}}</span>
                          </div>
                        </template>
                        </Dropdown>
                        <buttonp class="w-8rem p-button-warning ml-2 " icon="pi pi-search" label="BUSCAR" @click.prevent="pressSearch()"/>
                    </div>
                </div>
        </div>
        
        <div v-if="resultReport.length > 0">
            <div class="card ml-6 mr-6 mt-3">
                <h4 class="text-black">TOTALES</h4>
                <h5 class="text-black">{{resultReport.length}} Colaboradoles evaluados.</h5>
            </div>

            <DataTable id="tableDocumentatio" :value="totals_report" responsiveLayout="scroll">
                <Column field="name" header="Tipo Estado" :sortable="true"></Column>
                <Column field="total" header="Total Usuarios" :sortable="true">
                    <template #body="slotProps">
                        <buttonp  v-if="slotProps.data.name != 'Valido'" :label="slotProps.data.total.toString()" @click="openDetailUser(slotProps.data.detail, slotProps.data.name)" autofocus />

                        <buttonp v-if="slotProps.data.name == 'Valido'" :label="slotProps.data.total.toString()+' de '+' '+resultReport.length.toString()+' empleados tiene documentos validos'" v-tooltip.left="'CANTIDAD DE TRABAJADORES CON DOCUMENTACION VALIDA (MAS DE 18 DOCUMENTOS VALIDOS)'" autofocus class @click="openDetailUser(slotProps.data.detail, slotProps.data.name)" />

                        <!--buttonp class="p-button-warning"  v-if="slotProps.data.name == 'Valido'" :label="getTotalValidDocuALERT(slotProps.data.detail)" v-tooltip.left="'CANTIDAD DE TRABAJADORES CON DOCUMENTACION VALIDA (MAYOR IGUAL A 15 DOCUMENTOS VALIDOS)'"  autofocus @click="openDetailUser(slotProps.data.detail, slotProps.data.name)" />

                        <buttonp class="p-button-danger" v-if="slotProps.data.name == 'Valido'" :label="getTotalValidDocuCRITIC(slotProps.data.detail)" v-tooltip.left="'CANTIDAD DE TRABAJADORES CON DOCUMENTACION VALIDA (MENOR A 15 DOCUMENTOS VALIDOS)'" autofocus  @click="openDetailUser(slotProps.data.detail, slotProps.data.name)" /-->

                    </template>
                </Column>
            </DataTable>
             <br /><br /><br /> <br /><br /><br />
        </div>
        <div v-else>
            <h2>Sin Resultados</h2>
        </div>
        <Dialog :header="selected_total_type" v-model:visible="displayDetailUser" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '80vw'}">

            <DataTable v-if="selectedDetailsUser.length > 0" id="tableDocumentatio" :value="selectedDetailsUser" responsiveLayout="scroll">
                <Column header="Nombre Colaborador" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.employee_name.toString()" @click="openDetailColaborator(slotProps.data.detail_docs, slotProps.data.employee_name, slotProps.data.emp, 'Nombre Colaborador', slotProps.index)" autofocus />
                    </template>
                </Column>

                <Column v-if="selected_total_type === 'Valido' || selected_total_type === null"
                 header="#Valida" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.valid_docu.toString()" @click="openDetailColaborator(slotProps.data.d_count_valid, slotProps.data.employee_name, slotProps.data.emp, 'Valida', slotProps.index)" autofocus />
                     </template>
                </Column>
                <Column v-if="selected_total_type === 'Proceso Renovacion' || selected_total_type === null"
                 header="#En Renovacion" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.process_renew_docu.toString()" @click="openDetailColaborator(slotProps.data.d_count_process, slotProps.data.employee_name, slotProps.data.emp, 'En Renovacion', slotProps.index)" autofocus />
                    </template>
                </Column>
                <Column v-if="selected_total_type === 'Alertas' || selected_total_type === null" header="#Alerta" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.alert_docu.toString()" @click="openDetailColaborator(slotProps.data.d_count_alert, slotProps.data.employee_name, slotProps.data.emp, 'Alerta', slotProps.index)" autofocus />
                    </template>
                </Column>
                <Column v-if="selected_total_type === 'Criticos' || selected_total_type === null" header="#CRITICOS" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.count_critic.toString()" @click="openDetailColaborator(slotProps.data.d_count_critic, slotProps.data.employee_name, slotProps.data.emp, 'Alerta', slotProps.index)" autofocus />
                    </template>
                </Column>
                <Column v-if="selected_total_type === 'Expirado' || selected_total_type === null" header="#Expirados" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.expired_docu.toString()" @click="openDetailColaborator(slotProps.data.d_count_expired, slotProps.data.employee_name, slotProps.data.emp, 'Expirados', slotProps.index)" autofocus />
                    </template>
                </Column>
                <!--Column v-if="selected_total_type === 'Rechazado' || selected_total_type === null" header="#Rechazado" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.count_rejected.toString()" @click="openDetailColaborator(slotProps.data.d_count_rejected, slotProps.data.employee_name, slotProps.data.emp, 'Rechazado', slotProps.index)" autofocus />
                    </template>
                </Column-->
                <Column v-if="selected_total_type === 'Revisar' || selected_total_type === null" header="#Revisar" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.count_check.toString()" @click="openDetailColaborator(slotProps.data.d_count_check, slotProps.data.employee_name, slotProps.data.emp, 'Revisar', slotProps.index)" autofocus />
                    </template>
                </Column>
                <Column v-if="selected_total_type === 'Renovado' || selected_total_type === null" header="#Renovados" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.count_renewed.toString()" @click="openDetailColaborator(slotProps.data.d_count_renewed, slotProps.data.employee_name, slotProps.data.emp, 'Renovados', slotProps.index)" autofocus />
                    </template>
                </Column>
                <Column header="Documentos Totales" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.total_docs.toString()" @click="openDetailColaborator(slotProps.data.detail_docs, slotProps.data.employee_name, slotProps.data.emp, 'Totales', slotProps.index)" autofocus />
                    </template>
                </Column>
            </DataTable>

            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="displayDetailUser = false;" autofocus />
            </template>
        </Dialog>
        <Dialog :header="selected_colaborator_name" v-model:visible="displayDetailDocs" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '80vw'}">

            <DataTable id="tableDetailCOlaborador" :value="selectedDetails" responsiveLayout="scroll">
                <!--Column header="ID" :sortable="true">
                    <template #body="slotProps">
                    <buttonp :label="slotProps.data.documentation_implementation_id.toString()" @click="openDetailDocumentation(slotProps.data.documentation_implementation_id)" autofocus />
                    </template>
                </Column-->
                <Column field="documentation_id" header="Tipo Documento" :sortable="true">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.documentation_id !== null">{{getDocumentationById(slotProps.data.documentation_id)}}</span>
                    </template>
                </Column>
                <Column field="expiration" header="Expiracion" :sortable="true">
                    <template #body="slotProps">
                        <buttonp v-if="slotProps.data.calculate_status == 'ALERT'" :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-warning" icon="pi pi-exclamation-circle" v-tooltip.left="'DOCUMENTO EN ALERTA'" autofocus/>
                        <buttonp v-if="slotProps.data.calculate_status == 'CRITIC'"  :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-danger" icon="pi pi-exclamation-circle" v-tooltip.left="'DOCUMENTO CRITICO'" autofocus disabled/>
                        <buttonp v-if="slotProps.data.calculate_status == 'EXPIRED'"  :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-danger is-bold" icon="pi pi-times" v-tooltip.left="'DOCUMENTO EXPIRADO'" autofocus/>
                        <buttonp v-if="slotProps.data.calculate_status == 'OK'"  :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-success" icon="pi pi-check" v-tooltip.left="'DOCUMENTO NO EXPIRADO'" autofocus/>
                    </template>
                </Column>
                <Column header="ESTADO" :sortable="true">
                   <template #body="slotProps">
                        <statustag :status="slotProps.data.status"></statustag>
                   </template>
                </Column>
                <!--Column field="limit_renew" header="Limite C.Renovacion" :sortable="true">
                    <template #body="slotProps">
                        {{getLocalDate(slotProps.data.limit_renew)}}
                    </template>
                </Column-->
                <!--Column field="start_renew" header="Comenzo Renovacion" :sortable="true"></Column-->
                <!--Column field="renew" header="Fecha Renovacion" :sortable="true"></Column-->
                <Column header="Acciones" style="width: 200px;">
                    <template #body="slotProps">

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-pencil" v-tooltip.left="'Editar Documento'" @click="openDetailDocumentation(slotProps.data.documentation_implementation_id)" autofocus :disabled="slotProps.data.status === 'STARTRENEW' || slotProps.data.status === 'REJECTED' || slotProps.data.status === 'REJECT' || slotProps.data.status === 'RENEW'" />

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-history" v-tooltip.left="'Iniciar Renovacion Documento'" @click="startRenewProcess(slotProps.data.documentation_implementation_id)" autofocus :disabled="slotProps.data.status === 'STARTRENEW' || slotProps.data.status === 'REJECTED' || slotProps.data.status === 'REJECT' || slotProps.data.status === 'RENEW'"/>

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-sign-out" v-tooltip.left="'Finalizar Renovacion'" @click="finishRenew(slotProps.data.documentation_implementation_id, slotProps.data.documentation_id, slotProps.data.asigned_id)" autofocus :disabled="slotProps.data.status === 'REJECTED' || slotProps.data.status === 'REJECT' || slotProps.data.status === 'RENEW'"/>

                        <!--buttonp label="" class="ml-1 p-button-sm" icon="pi pi-exclamation-circle" v-tooltip.left="'Rechazar Documento'" @click="rejectedDocument(slotProps.data.documentation_implementation_id)" autofocus /-->
                    

                        <!--buttonp label="" class="ml-1 is-red p-button-sm" icon="pi pi-trash" v-tooltip.left="'Eliminar Documento'" @click="deleteDocumentation(slotProps.data.documentation_implementation_id)" autofocus /-->

                    </template>
                </Column>
            </DataTable>

            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="displayDetailDocs = false;" autofocus />
            </template>

        </Dialog>

        <Dialog :header="'Editar Documentation'" v-model:visible="displayEditDoc" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 100vh">
                <editdocucomponent :docu_im_id="selectedIdDocumentation" v-on:ok-edit="refreshData"></editdocucomponent>
            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="displayEditDoc = false;" autofocus />
            </template>
        </Dialog>


        <Dialog :header="'Comienza Proceso de Renovacion'" v-model:visible="displaystartRenewProcess" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 100vh">
                <startrenewdocu :id_docu_impl="selectedIdDocumentation" v-on:ok-edit="refreshDataProcess"></startrenewdocu>
            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="displaystartRenewProcess = false;" autofocus />
            </template>
        </Dialog>

        <Dialog :header="'Finalizar Renovacion Documentacion'" v-model:visible="displayfinishRenew" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 100vh">
                <renewdocu :id_docu_impl="selectedIdDocumentation" :id_docu="selectedDocuType" :employee_id="selectedAsigned" v-on:ok-edit="refreshDataRenew"></renewdocu>

            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="displayfinishRenew = false;" autofocus />
            </template>
        </Dialog>


        <Dialog :header="'Rechazado Documentacion'" closable=false v-model:visible="displayrejectedDocument" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 100vh">
                <rejectdocu :id_docu_impl="selectedIdDocumentation" v-on:ok-edit="refreshData"></rejectdocu>
            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="displayrejectedDocument = false;" autofocus />
            </template>
        </Dialog>

        <Dialog :header="'Borrar Documentacion'" v-model:visible="displaydeleteDocumentation" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 20%; min-height: 20vh">
                <deletedocu :id_docu_impl="selectedIdDocumentation" :only_confirmation="true" v-on:ok-edit="refreshData"></deletedocu>            
            </div>
             <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="displaydeleteDocumentation = false;" autofocus />
            </template>
        </Dialog>


    </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    import DocumentationService from '@/service/DocumentationService';
    import ContractService from '@/service/ContractService';
    import Statustag from '@/components/StatusTag';
    import Editdocucomponent from '@/components/EditDocuComponent';
    import Deletedocu from '@/components/DeleteDocuComponent'
    import Rejectdocu from '@/components/RejectDocuComponent'
    import Startrenewdocu from '@/components/StartRenewDocuComponent'
    import Renewdocu from '@/components/RenewDocuComponent'
    import moment from "moment";


    let AdminAllEmployeeDocu = {};
    (function () {
        this.name = 'all-colaborator-reports';

        this.created = function () {
            this.getAllContractUser();
            this.getAllDocumentation();
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                contracts: [],
                selectedContract: null,
                resultReport: [],
                selectedDetails: [],
                documentations: [],
                selected_colaborator: null,
                selected_colaborator_name: null,
                totals_report: [],
                selected_total_type: null,
                selectedDetailsUser: [],
                selectedIdDocumentation: null,
                selectedDocuType: null,
                selectedAsigned: null,
                displayEditDoc: false,
                displaystartRenewProcess: false,
                displayfinishRenew: false,
                displayrejectedDocument: false,
                displaydeleteDocumentation: false,
                displayDetailUser: false,
                displayDetailDocs: false,
                column_selected_users: null,
                index_user_select: null,
                selectedIdDocuAfterRenew: null,
                is_renew_process: false,
            }

        };
        this.methods = {
            getTotalValid(detail, doit, value){
                const isoStr = new Date().toISOString();
                var total_valid_user = 0;
                var count_ok = 0;
                for (var i = 0; i < detail.length; i++) {
                    count_ok = 0;
                    for (var z = 0; z < detail[i].detail_docs.length; z++) {
                        if(detail[i].detail_docs[z].expiration > isoStr && detail[i].detail_docs[z].status === 'VALID'){
                            count_ok = count_ok + 1;
                        }
                    }
                    if(doit === 'OK'){
                        if(count_ok >= value){
                            total_valid_user = total_valid_user + 1;
                        }
                    }
                    if(doit === 'ALERT'){
                        if(count_ok >= value && count_ok <= (value+3)){
                            total_valid_user = total_valid_user + 1;
                        }
                    }
                    if(doit === 'CRITIC'){
                        if(count_ok < value){
                            total_valid_user = total_valid_user + 1;
                        }
                    }
                }
                return total_valid_user.toString();
            },
            getTotalValidDocuALERT(detail){
                return this.getTotalValid(detail, 'ALERT', 15);
            },
            getTotalValidDocuCRITIC(detail){
                return this.getTotalValid(detail, 'CRITIC', 15);
            },
            getTotalValidDocu(detail){
                return this.getTotalValid(detail, 'OK', 18);
            },
            refreshDataRenew(value){
                this.selectedIdDocuAfterRenew = value;
                this.displayEditDoc = false;
                this.displaystartRenewProcess = false;
                this.displayfinishRenew = false;
                this.displayrejectedDocument = false;
                this.displaydeleteDocumentation =  false;
                this.displayDetailUser = false;
                this.displayDetailDocs = false;
                this.getAllReport();
            },
            refreshData(){
                this.displayEditDoc = false;
                this.displaystartRenewProcess = false;
                this.displayfinishRenew = false;
                this.displayrejectedDocument = false;
                this.displaydeleteDocumentation =  false;
                this.displayDetailUser = false;
                this.displayDetailDocs = false;
                this.getAllReport();
            },
            refreshDataProcess(){
                this.displayEditDoc = false;
                this.displaystartRenewProcess = false;
                this.displayfinishRenew = false;
                this.displayrejectedDocument = false;
                this.displaydeleteDocumentation =  false;
                this.displayDetailUser = false;
                this.displayDetailDocs = false;
                this.is_renew_process = true;
                this.getAllReport();
            },
            startRenewProcess(id){
                this.displaystartRenewProcess = true;
                this.selectedIdDocumentation = id;
            },
            finishRenew(id_imple, documentation_id, asigned_id){
                this.displayfinishRenew = true;
                this.selectedIdDocumentation = id_imple;
                this.selectedDocuType = documentation_id;
                this.selectedAsigned = asigned_id;
            }, 
            rejectedDocument(id){
                this.displayrejectedDocument = true;
                this.selectedIdDocumentation = id;
            }, 
            deleteDocumentation(id){
                this.displaydeleteDocumentation = true;
                this.selectedIdDocumentation = id;
            },
            getDocumentationById(id){
               return  this.documentations.find(item => item.documentation_id === id).name;
            },
            getAllDocumentation(){
                this.$store.dispatch('form/submit', true);
               DocumentationService.allDocumentation().then((response) => {
                    this.documentations = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            exportExcel(){
                console.log('export');
            },
            openDetailDocumentation(id){
                this.displayEditDoc=true;
                this.selectedIdDocumentation = id;
                //this.$store.dispatch('employee/set-employee', this.selected_colaborator);
                //this.$router.push({name: 'admin-documentation', params: { docu_id: id } });
            },
            getLocalDate(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('YYYY-MM-DD');
                  return local;
                }
            },
            openDetailUser(details, type){
                this.selectedDetailsUser = [];
                this.displayDetailUser = true;
                this.selectedDetailsUser = details;
                this.selected_total_type = type;
            },
            openDetailColaborator(details, cola_name, emp, column_select, index_user_select){
                //this.displayDetailUser = false;
                this.selectedDetails = [];
                this.selected_colaborator = emp;
                this.selected_colaborator.first_name1 = cola_name;
                this.selected_colaborator_name = cola_name;
                this.displayDetailDocs = true;
                this.selectedDetails = details;
                this.column_selected_users = column_select;
                this.index_user_select = index_user_select;
            },
            closeDetailUser(){
                this.displayDetailUser = false;
            },
            closeDetail(){
                this.displayDetailDocs = false;
            },
            getAllContractUser(){
                ContractService.getByEmployee(this.username).then((response) => {
                    this.contracts = response.data;
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },

            pressSearch(){
                this.selectedIdDocuAfterRenew = null;
                this.selected_total_type = null;
                this.column_selected_users = null;
                this.index_user_select = null;
                this.getAllReport();
            },

            getAllReport() {
                this.$store.dispatch('form/submit', true)
                let result = {isError: false, message: null}
                this.resultReport = [];
                this.totals_report = [];

                var contractsid = null
                if(this.selectedContract != null)
                    contractsid = this.selectedContract.contract_id

                DocumentationService.allColaboratorReport(contractsid)
                    .then(response => {
                         this.resultReport = response.data.employee_all_detail;
                         this.totals_report = response.data.total_ed;
                        if(response.data.length === 0){
                            result.isError = true;
                            result.message = 'Sin resultados';
                            this.$store.dispatch('form/result', result);
                        }else{
                            console.log('evaluando tipo documentacion seleccionada');
                            console.log(this.selected_total_type);
                            if(this.selected_total_type !== null){
                                
                                for (var i = 0; i < this.totals_report.length; i++) {
                                    if(this.totals_report[i].name === this.selected_total_type){
                                            this.selectedDetailsUser = this.totals_report[i].detail;
                                            this.displayDetailUser = true;
                                            break;
                                    }
                                }

                                if(this.column_selected_users !== null && this.index_user_select !==null){

                                    if(this.selectedIdDocuAfterRenew !== null){
                                        this.selectedDetails = this.selectedDetailsUser[this.index_user_select].d_count_valid
                                            this.displayDetailDocs = true;
                                            this.selectedIdDocumentation = this.selectedIdDocuAfterRenew;
                                            this.displayEditDoc = true;
                                    }else{
                                        if(this.column_selected_users === 'Totales' || this.column_selected_users === 'Nombre Colaborador'){
                                        this.selectedDetails = this.selectedDetailsUser[this.index_user_select].detail_docs
                                        }
                                        if(this.column_selected_users === 'Renovados'){
                                            this.selectedDetails = this.selectedDetailsUser[this.index_user_select].d_count_renewed
                                        }
                                        if(this.column_selected_users === 'Revisar'){
                                            this.selectedDetails = this.selectedDetailsUser[this.index_user_select].d_count_check
                                        }
                                        if(this.column_selected_users === 'Rechazado'){
                                            this.selectedDetails = this.selectedDetailsUser[this.index_user_select].d_count_rejected
                                        }
                                        if(this.column_selected_users === 'Expirados'){
                                            this.selectedDetails = this.selectedDetailsUser[this.index_user_select].d_count_expired
                                        }
                                        if(this.column_selected_users === 'Alerta'){
                                            this.selectedDetails = this.selectedDetailsUser[this.index_user_select].d_count_alert
                                        }
                                        if(this.column_selected_users === 'En Renovacion'){
                                            this.selectedDetails = this.selectedDetailsUser[this.index_user_select].d_coun_process
                                        }
                                        if(this.column_selected_users === 'Valida'){
                                            this.selectedDetails = this.selectedDetailsUser[this.index_user_select].d_count_valid
                                        }

                                         if(this.is_renew_process){
                                            this.selectedDetails = this.selectedDetailsUser[this.index_user_select].d_coun_process
                                        }

                                        this.displayDetailDocs = true;
                                        this.is_renew_process = false;
                                    }

                                }
                            }
                        }

                        this.$store.dispatch('form/submit', false);
                    })
                    .catch(err => {
                      console.log(err);
                       this.$store.dispatch('form/submit', false)
                    });
                    
            }
        };
        this.components = {Statustag, Editdocucomponent, Deletedocu, Rejectdocu, Startrenewdocu, Renewdocu};
        this.computed = {
            ...mapGetters({
                username: 'auth/user'
            }),
        };
        this.watch = {

        };
        this.props = [];
    }).apply(AdminAllEmployeeDocu);

    export default AdminAllEmployeeDocu
</script>
