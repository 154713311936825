<template id="doActivity">
    <span>
        <div class="md-min-width card m-5">
                <h3 v-if="show_title" class="text-black">RECHAZO DE DOCUMENTACION</h3>
                <p class="text-black">Realizando esta accion usted estara rechazando la documentacion y perdera la vigencia.</p>
                <h5 class="text-black">Observacion de rechazo</h5>
                <Textarea v-model="observation_reject" rows="5" cols="30" />
                <br />
                <buttonp class="w-10rem mt-5 p-button-warning" icon="pi pi-save" label="Rechazar Documentacion" @click.prevent="reject()"/>
        </div>
    </span>
</template>


<script>
    import {mapGetters} from 'vuex';
    import DocumentationService from '@/service/DocumentationService';

    let RejectDocuCoponent = {};
    (function () {
        this.name = 'reject-documentation-component';
        // let _self = null;

        this.created = function () {

        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                docu_implementation: null,
                observation_reject: null,
            }
        };
        this.methods = {
            reject() {
                this.$store.dispatch('form/reset');
                this.$store.dispatch('form/submit', true)
                this.$store.dispatch('form/error', {
                    ocu_oblig: false,
                    asigned_oblig: false,
                });

                if (this.$store.getters['form/invalid-fields'].length > 0) {
                    this.$store.dispatch('form/submit', false)
                    return
                }

                DocumentationService.documentationReject(this.observation_reject, this.id_docu_impl)
                    .then(response => {
                        var isError =  false;
                        if(response.data.status === 'ERROR'){
                            isError = true;
                        }else{
                            this.$emit('okEdit');
                            //this.$router.push({name: 'home'});
                        }
                        this.$store.dispatch('form/result', {isError: isError, message: response.data.message});
                        this.$store.dispatch('form/submit', false)
                    })
                    .catch(err => {
                      console.log(err);
                      this.$store.dispatch('form/submit', false)
                    });
            },
        };
        this.computed = {
            ...mapGetters({
                error: 'form/error-states'
            }),
        };
        this.watch = {
            
        };
        this.props = ['id_docu_impl', 'show_title'];
    }).apply(RejectDocuCoponent);

    export default RejectDocuCoponent
</script>
