import axios from "axios";

export default {
    
    getAll() {
        const url = `${process.env.VUE_APP_API_CORE_URL}/employees`;
        return axios.get(url)
    }


}
